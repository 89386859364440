import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MessageType from "./MessageType";

interface AlertDialogProps {
    hasError(): boolean,
    setError(v: boolean): void,
    getErrorMessage(): string | null,
    getMessageType(): MessageType | null
};

const getMessageTitle = (messageType:  MessageType | null) => {
  switch (messageType) {
    case MessageType.INFO:
      return "Información";
    case MessageType.WARN:
      return "Alerta";
    case MessageType.DEBUG:
        return "Información de desarrollo";
    default:
      return "Error inesperado";
  }
}

export default function AlertDialog(props: AlertDialogProps) {
  const open = props.hasError;
  const setOpen = props.setError;
  const getErrorMessage = props.getErrorMessage
  const getMessageType = props.getMessageType

  return (
    <div>
      <Dialog
        open={open()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {getMessageTitle(getMessageType())}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getErrorMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => setOpen(false) } color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}