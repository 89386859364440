import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Drawer, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';

type AnchorValue = 'left' | 'top' | 'right' | 'bottom' | undefined;

interface Faq {
    title: string;
    text: string
}

const faqs: Faq[] = [
    {title: "¿Qué es DocSpot?", text: "DocSpot es el servicio para compartir documentos desde el gestor documental de iArchiva. Con DocSpot compartir documentación con personal ajeno a la empresa es tan sencillo como pulsar un botón."},
    {title: "¿Puedo usar DocSpot sin ser usuario de iArchiva?", text: "En el futuro el servicio estará disponible de manera independiente a través de un portal corporativo específico para las empresas que deseen usarlo de manera independiente o necesiten funcionalidades adicionales, pero actualmente sólo los usuarios de iArchiva pueden enviar archivos."},
    {title: "¿Es seguro usar DocSpot?", text: "DocSpot utiliza servidores seguros. Además, descargar un documento requiere disponer de su dirección de descarga, que es única, e introducir el PIN que se solicita y que sólo es válido para ella. Sin esa combinación no es posible acceder al documento."}
];

const useStyles = makeStyles((theme) => ({
    accordionHeading: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
    },
    faqAccordion: {
        width: "calc(100vw*0.5)",
        minWidth: "20rem",
        color: "#006064",
    }
}));

interface DocSpotFaqProps {
    open: boolean;
    toggleOpen(): void;
}

const DocSpotFaq = (props: DocSpotFaqProps) => {    
    const anchorPosition: AnchorValue = "right";
    const [anchorState, setAnchorState] = React.useState<boolean>(true);
    const classes = useStyles();

    const faqAccordion = () => faqs.map((element, index) => {
        return (<Accordion className={classes.faqAccordion}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
            >
            <Typography className={classes.accordionHeading}>{element.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {element.text}
            </AccordionDetails>
        </Accordion>);
    });

    return (
        <React.Fragment key={anchorPosition}>
            <Drawer anchor={anchorPosition} open={props.open}>
                <Grid container>
                    <Grid item>
                        <IconButton aria-label="close" color="primary" onClick={props.toggleOpen}>
                            <CloseIcon fontSize="default" />
                        </IconButton>
                    </Grid>
                </Grid>
                { faqAccordion() }
            </Drawer>
        </React.Fragment>
    );
}

export default DocSpotFaq;