import React, { useEffect, useState } from 'react';
import './App.css';
import { ThemeProvider } from '@material-ui/core';
import FileInfo from './FileInfo';
import { useErrorHandler } from 'react-error-boundary';
import MessageType from './MessageType';
import theme from './theme';
import Portrait from './Portrait';
import Landscape from './Landscape';

const App = () => {

  const [error, setError] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<MessageType | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [fileInfo, setFileInfo] = useState<FileInfo>({});
  const [faqState, setFaqState] = useState<boolean>(false);
  const useError = useErrorHandler();

  const [columnOrientation, setColumnOrientation] = useState<boolean>();
  
  useEffect(() => {
    setFileInfo(loadParams());
    window.history.replaceState({}, document.title, window.location.pathname);
    const resizeHandler = (e: Event) => {
      const w: Window = e.target as Window;
      setColumnOrientation((w.innerWidth / 8) * 6 < w.innerHeight);
    };
    setColumnOrientation((window.innerWidth / 8) * 6 < window.innerHeight);
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const loadParams = (): FileInfo =>  {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const o: FileInfo = {};
    o.customer = urlParams.get("customer") as string;
    o.sender = urlParams.get("sender") as string;
    o.recipient = urlParams.get("recipient") as string;
    o.id = urlParams.get("id") as string;
    o.password = "";
    return o;
  }

  const fileInfoFunc = () => {
    return fileInfo;
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        { columnOrientation ? <Portrait fileInfo={fileInfo} fileInfoFunc={fileInfoFunc}/> : <Landscape fileInfo={fileInfo} fileInfoFunc={fileInfoFunc}/>}
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
