import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import './App.css';
import { Grid, Paper, TextField, Fab, FormLabel, CircularProgress, IconButton, ThemeProvider, Typography, Link } from '@material-ui/core';
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import CloudDownload from "@material-ui/icons/CloudDownload";
import CheckIcon from "@material-ui/icons/Check";
import download from './Downloader';
import AlertDialog from './AlertDialog';
import FileInfo from './FileInfo';
import { useErrorHandler } from 'react-error-boundary';
import MessageType from './MessageType';
import RequestError from './RequestError';
import DocSpotFaq from './DocSpotFaq';
import HelpIcon from '@material-ui/icons/Help';
import UiProps from './UiProps';
import {DOCSPOT_LOGO_IMAGE, IARCHIVA_LOGO, WALLPAPER, DOWNLOAD_URL} from './Constants';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    width: "100vw",
    height: "100vh",
    minHeight: "100vh",
    backgroundImage: `url(${WALLPAPER})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  centralGrid: {
    width: "100vw",
    height: "calc(100vh*0.7)"
  },
  downloadLabel: {
    color: "#444",
    fontWeight: "bold"
  },
  mainHeader: {
    width: "100vw",
    height: "calc(100vh * 0.3)",
    minHeight: "3rem",
    verticalAlign: "middle"
  },
  footer: {
    width: "100vw",
    height: "calc(100vh*0.09)"
  },
  footerLink: {
    padding: "0 0.25rem 0 0.25rem",
    fontSize: "0.9rem",
    fontFamily: "Roboto"
  },
  logoImage: {
    height: "calc((100vw * 0.12) - 2rem)",
    padding: "1rem 0 0 1rem",
    minHeight: "3rem",
    maxHeight: "calc((100vh*0.075/5)*8)",
    backgroundImage: `url(${DOCSPOT_LOGO_IMAGE})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "darken, luminosity"
  },
  logoIArchiva: {
    height: "calc((100vh*0.075/5)*8)",
    padding: "0",
    margin: "0",
    minHeight: "3rem",
    backgroundImage: `url(${IARCHIVA_LOGO})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundBlendMode: "darken, luminosity"
  },
  wrapper: {
    margin: theme.spacing(2),
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700]
    }
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  },
  paper: {
    padding: "1rem 1rem 1.5rem 1rem"
  },
  passswordTextField: {
    paddingRight: "1rem"
  },
  logo: {
    margin: 0, 
    padding: "1rem 0 0 1rem",
  },
  bannerH1: {
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    fontSize: "calc(100vh*0.075)",
    lineHeight: "calc(100vh*0.075)",
  },
  bannerH1Highlighted: {
    fontFamily: "Roboto Condensed",
    color: theme.palette.primary.main,
    fontSize: "calc((100vh*0.075/5)*8)",
    lineHeight: "calc((100vh*0.075/5)*8)",
  },
  bannerH2: {
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    fontSize: "calc((100vh*0.075/5)*3)",
    lineHeight: "calc((100vh*0.075/5)*3)",
  },
  bannerH3: {
    fontFamily: "Roboto Condensed",
    textTransform: "uppercase",
    fontSize: "calc((100vh*0.075/5)*2)",
    lineHeight: "calc((100vh*0.075/5)*2)",
  },
}));

const Landscape = (props: UiProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [percent, setPercent] = useState<number>(0);
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [messageType, setMessageType] = useState<MessageType | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [fileInfo, setFileInfo] = useState<FileInfo>(props.fileInfoFunc());
  const [faqState, setFaqState] = useState<boolean>(false);
  const useError = useErrorHandler();
  
  const classes = useStyles();
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  const toQueryString = (fileInfo: FileInfo): string  => {
    let queryString = Object
      .keys(fileInfo)
      .filter(key => (fileInfo as any)[key] != null)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent((fileInfo as any)[key])}`)
      .join('&');
    if (queryString !== "") {
      queryString = "?" + queryString;
    }
    return queryString;
  }  

  const requestFile = async () => {
    let fileInfo: FileInfo = props.fileInfoFunc();
    fileInfo.password = password;
    if (!loading && !success) {
      setPercent(0);
      setSuccess(false);
      setLoading(true);
      try {
        await download(`${DOWNLOAD_URL}${toQueryString(fileInfo)}`, (p: number) => setPercent(p));
        setSuccess(true);
      } catch (e) {
        if (e instanceof RequestError) {
          setMessageType((e as RequestError).messageType);
          setMessage(e.message);
        } else {
          setMessageType(MessageType.ERROR);
          setMessage("Se ha producido un error inesperado al solicitar el archivo del servidor.");
        }
        setError(true);
      }
      setLoading(false);
    }
    if (success) {
      setMessage("Ya has descargado el archivo.");
      setMessageType(MessageType.INFO);
      setError(true);
    }
  };

  const handleButtonClick = (e: React.MouseEvent) => {
    requestFile();
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      requestFile();
    }
  }

  const toggleFaq = () => {
    setFaqState((faqState) => !faqState);
  };

  const headerWidget = () => {
    return(
      <Grid container>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={6}>
              <h1 className={classes.logo} aria-label="DocSpot" title="DocSpot">
                <div className={classes.logoImage}/>
              </h1>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction="row-reverse">
            <Grid item>
              <IconButton aria-label="close" color="primary" onClick={toggleFaq}>
                <HelpIcon fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const downloadWidget = () => {
    return(
      <Paper className={classes.paper} elevation={10} >
        <FormLabel className={classes.downloadLabel}>Descargar Archivo</FormLabel>            
        <Grid container direction="row">
          <Grid item>
            <TextField
              id="file-password"
              label="PIN"
              type="password"
              autoComplete="current-password"
              value={password}
              onKeyPressCapture={ handleKeyPress }
              onChange={(e) => { setPassword(e.currentTarget.value)}} 
              className={classes.passswordTextField}
            />
          </Grid>
          <Grid item>
            <div className={classes.wrapper}>
              <Fab
                aria-label="Descargar Archivo"
                color="primary"
                className={buttonClassname}
                onClick={ (e) => handleButtonClick(e) } 
              >
                {success ? (
                  <CheckIcon />
                ) : loading ? (
                  percent.toFixed(0) + " %"
                ) : (
                  <CloudDownload />
                )}
              </Fab>
              {loading && (
                <CircularProgress
                  size={68}
                  className={classes.fabProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  const textBanner = (classes: any) => {
    return(
      <Grid container direction="row">
        <Grid item>
          <h1 className={classes.logo} aria-label="iArchiva" title="iArchiva">
            <div className={classes.logoIArchiva}/>
          </h1>
          <Typography variant="h1" className={classes.bannerH1}>Más de</Typography>
          <Typography variant="h1" className={classes.bannerH1Highlighted}>60.000.000</Typography>
          <Typography variant="h1" className={classes.bannerH1}>de documentos</Typography>
          <Typography variant="h1" className={classes.bannerH1}>gestionados</Typography>
          <Typography variant="h2" className={classes.bannerH2}>Ahorra tiempo y utiliza</Typography>
          <Typography variant="h2" className={classes.bannerH2}>los recursos en procesos</Typography>
          <Typography variant="h2" className={classes.bannerH2}>más productivos.</Typography>
          <Typography variant="h3" className={classes.bannerH3}>Llega iArchiva, la gestión</Typography>
          <Typography variant="h3" className={classes.bannerH3}>documental eficaz e inteligente.</Typography>
        </Grid>
      </Grid>
    );
  };

  const footerWidget = () => {
    return(
      <Grid xs={12} container direction="row" alignItems="center" justify="center" className={classes.footer}>
        <Grid item direction="row" alignItems="center" justify="center">
          <Link component="a" className={classes.footerLink} href="https://www.iarchiva.com">Acerca de iArchiva</Link>
        </Grid>
        <Grid item direction="row" alignItems="center" justify="center">
          <Link component="a" className={classes.footerLink} href="mailto:eregalado@iarchiva.com?subject=DocSpot%20Ayuda">Ayuda</Link>
        </Grid>
        <Grid item direction="row" alignItems="center" justify="center">
          <Link component="a" className={classes.footerLink} href=" https://www.iarchiva.com/aviso-legal">Info legal</Link>
        </Grid>
        <Grid item direction="row" alignItems="center" justify="center">
          <Link component="a" className={classes.footerLink} href="mailto:eregalado@iarchiva.com?subject=DocSpot%20Spam">Spam</Link>
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
        <DocSpotFaq open={faqState} toggleOpen={toggleFaq}/>
        <AlertDialog 
          hasError={ () => error } 
          setError={setError} 
          getErrorMessage={() => message} 
          getMessageType={() => messageType} />
        <Grid container className={classes.mainGrid} direction="row">
          <Grid item xs={12}>
            { headerWidget() }
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.centralGrid} alignContent="center">
              <Grid item xs={12}>
                <Grid container direction="row" alignContent="flex-start" alignItems="center">
                  <Grid item xs={6}>
                    <Grid container direction="row" justify="flex-start">
                      <Grid item style={{padding: "0 0 0 calc(100vw*0.05)"}}>
                        { downloadWidget() }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <Grid container direction="row-reverse">
                      <Grid item style={{padding: "0 calc(100vw*0.05) 0 0"}}>
                        { textBanner(classes) }
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            { footerWidget() }
          </Grid>
        </Grid>
    </React.Fragment>
  );
}

export default Landscape;