import { saveAs } from "file-saver";
import { DOWNLOAD_HOST } from "./Constants";
import ErrorResponse from "./ErrorReponse";
import MessageType from "./MessageType";
import RequestError from "./RequestError";

const getFilenameFromContentDisposition = (headers: Headers): string | undefined => {
    const contentDisposition: string | null = headers.get("Content-Disposition");
    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches != null && matches[1]) { 
          return matches[1].replace(/['"]/g, '');
        }
    }
}

const getPresignedUrl = async (url: string): Promise<string | null> => {
    let error = null;
    try {
        const response: Response = await fetch(url, {method: 'GET', mode: 'cors'});
        const status = response.status;
        if (status === 200) {
            const presignedUrl: string = await response.text();
            return presignedUrl;
        } else if (status === 400) {
            error = await response.json();
        } else {
            error = await response.json();
        }
    } catch (e) {
        throw new RequestError(e.message, MessageType.ERROR);
    }
    if (error !== null) {
        throw new RequestError(error.message, MessageType.ERROR);
    }
    return null;
}

const donwload = async (url: string, callback : (value: number) => void): Promise<void> => {
    let requestError: RequestError | null = null;
    let presignedUrl: string | null = null;
    try {
        presignedUrl = await getPresignedUrl(url);
    } catch (e) {
        requestError = e;
    }
    try {
        if (presignedUrl !== null) {
            const response = await fetch(presignedUrl, {
                method: 'GET',
                mode: 'cors'
            });
            if (response.status === 200) {
                const contentLengthAsString: string | null = response.headers.get("Content-Length");
                const contentLength: number | null = contentLengthAsString ? parseInt(contentLengthAsString) :  null;
                const downloadSizeAsString = response.headers.get("Content-Length");
                const downloadSize = downloadSizeAsString ? parseInt(downloadSizeAsString) : null;
                if (response.body !== null) {
                    const reader = await response.body.getReader();
                    let chunks = [];
                    let receivedLength = 0;
                    while (true) {
                        const {done, value} = await reader.read();
                        if (done) break;
                        if (value) {
                            receivedLength += value.length;
                            if (contentLength) {
                                if (downloadSize) {
                                    const percentage: number = ((receivedLength/downloadSize) * 100)
                                    //await new Promise(resolve => setTimeout(resolve, 1000));
                                    callback(percentage);
                                }
                            }
                            chunks.push(value);
                        }
                    }
                    const fileName: string | null = response.headers.get("x-amz-meta-filename");
                    saveAs(new Blob(chunks), fileName === null ? undefined : fileName);
                }
            } else if (response.status === 400) {
                const res = await response.json() as ErrorResponse;
                requestError = new RequestError(res.message, MessageType.WARN);
            } else {
                const res = await response.json() as ErrorResponse;
                requestError = new RequestError(res.message, MessageType.ERROR);
            }
        }
    } catch (e)  {
        requestError = new RequestError("Ha fallado la petición al servidor.", MessageType.ERROR);
    }
    if (requestError !== null) {
        throw requestError;
    }
}

export default donwload;